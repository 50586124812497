import React, { useState, useEffect } from 'react';
import GeneralDrilldownTable from './GeneralDrilldownTable';
import {assignLevels, sumFieldByGroup} from '../utils'

const ForecastTableNew = ({ pastBudgets, currentBudget, currentYear, selectedSchool, updateGraphData}) => {

    
    const filteredPastBudgets = pastBudgets//.filter(item=>(item.DISTRICT_NAME===selectedSchool));
                                             //       ((item.YEAR===(currentYear-1).toString())|(item.YEAR===(currentYear-2).toString())));

    // calculate the LEVEL of each LABEL. i.e. PARENT == 'Self' is level 0, its children level 1, etc.
    // this will help us indent the table properly according to the level
    let levelDict = assignLevels(filteredPastBudgets, 'LABEL');
    filteredPastBudgets.forEach(item => item['LEVEL'] = levelDict[item.LABEL]);
    filteredPastBudgets.forEach(item => {
        item['functionCode'] = item['LABEL'];
        item['DESCRIPTION'] = 'Aggregate';
    });

    levelDict = assignLevels(currentBudget, 'functionCode');
    currentBudget.forEach(item => {
        item['LEVEL'] = levelDict[item.functionCode]
    });

    const currentBudgetChildren = currentBudget.map(item => ({
        ...item,
        PARENT: item.functionCode, // Add or update the PARENT field
      }));
      
    let aggregateArray = sumFieldByGroup(currentBudget, ['YEAR', 'functionCode', 'PARENT', 'LEVEL'], 'TOTAL_ALL_FUNDS')

    aggregateArray.forEach(item=> {
        item['DESCRIPTION'] = 'Aggregate'
    })

    let concatArray = filteredPastBudgets.concat(currentBudgetChildren.concat(aggregateArray));


    function filterNonZeroEntries(data) {
      // Create a map to track whether a LABEL has any non-zero TOTAL
      const labelHasNonZero = {};
    
      // Iterate through the data to check for non-zero TOTAL for each LABEL
      data.forEach((entry) => {
        const { functionCode, TOTAL_ALL_FUNDS } = entry;
        if (!labelHasNonZero[functionCode]) {
          labelHasNonZero[functionCode] = false; // Initialize to false if not already set
        }
        if (TOTAL_ALL_FUNDS !== 0) {
          labelHasNonZero[functionCode] = true; // Mark as true if TOTAL is non-zero
        }
      });
    
      // Filter the original array based on the map
      return data.filter((entry) => labelHasNonZero[entry.functionCode]);
    }

    const concatArrayFilt = filterNonZeroEntries(concatArray);

    return (
        <GeneralDrilldownTable
            data = {concatArrayFilt}
            tableDataFilters={`item.DESCRIPTION==='Aggregate' && item.YEAR > 2021 && !isNaN(item.TOTAL_ALL_FUNDS)`}
            tableDataFormats= {{'TOTAL_ALL_FUNDS': 'dollars'}}
            rowKey = 'functionCode'
            columnKey = 'YEAR'
            valueLabel = 'TOTAL_ALL_FUNDS'
            rowLevelFunctions = {{'graph' : true, 'edit' : true}}
            fiscalYear = {currentYear}
        />
    )

};

export default ForecastTableNew;
