export function getUniqueValues(data, key) {
  return [...new Set(data.map(item => item[key]))];
}

export const formatDollars = (num) => {
  return `$${Math.round(num).toLocaleString()}`;
};

export function sumFieldByGroup(data, groupFields, sumField) {
  // Create a map to store the grouped sums
  const groupMap = {};

  // Calculate sums for each group
  data.forEach(obj => {
    // Create a composite key for the group based on the specified fields
    const groupKey = groupFields.map(field => obj[field]).join('|');

    if (!groupMap[groupKey]) {
      // Initialize the grouped object with the fields and set the sumField to 0
      groupMap[groupKey] = { ...groupFields.reduce((acc, field) => {
        acc[field] = obj[field];
        return acc;
      }, {}), [sumField]: 0 };
    }

    // Add the sumField value to the appropriate group
    groupMap[groupKey][sumField] += obj[sumField];
  });

  // Return only the summarized objects
  return Object.values(groupMap);
}


export function assignLevels(data, labelKey) {

  let uniqueLabels = getUniqueValues(data, labelKey);

  const labelParentDict = data.reduce((acc, item) => {
    const label = item[labelKey];
    const parent = item['PARENT'];

    if (!acc[label]) {
      acc[label] = parent;
    }
    return acc;
  }, {});
  
  let levelDict = {};

  uniqueLabels.forEach((label_item) => {
    let level = 0;
    let parent = labelParentDict[label_item]
    
    while(parent!='Self'){
      level+=1
      parent = labelParentDict[parent]
    }
    levelDict[label_item] = level

  })

  return levelDict
}

export function getShade(value) {

  // Start with a light blue color (can adjust to any base RGB color)
  const baseColor = { r: 240, g: 248, b: 255 };
  
  // Calculate new RGB values by reducing each component based on the ratio
  const r = Math.floor(baseColor.r - 40*value);  
  const g = Math.floor(baseColor.g - 25*value);  
  const b = Math.floor(baseColor.b - 3*value);
  
  // Return the color as an RGB string
  return `rgb(${r}, ${g}, ${b})`;
}

export function arrToJSON(arr) {
  const jsonObject = {};
  arr.forEach(key => {
      jsonObject[key] = false;
  });
  return jsonObject
}