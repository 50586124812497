import React, { useState } from 'react';
import '../styles/Sidebar.css';

const Sidebar = ({ selectedDistrict, setActiveComponent, activeComponent, boardView, superAdmin }) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [isOverviewOpen, setIsOverviewOpen] = useState(false);
    const [isHistoricalOpen, setIsHistoricalOpen] = useState(false);
    const [isCurrentOpen, setIsCurrentOpen] = useState(false);
    const [isPlanningOpen, setIsPlanningOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsMinimized(!isMinimized);
    };

    const toggleOverview = () => setIsOverviewOpen(!isOverviewOpen);
    const toggleHistorical = () => setIsHistoricalOpen(!isHistoricalOpen);
    const toggleCurrent = () => setIsCurrentOpen(!isCurrentOpen);
    const togglePlanning = () => setIsPlanningOpen(!isPlanningOpen);
    const toggleSettings = () => setIsSettingsOpen(!isSettingsOpen);
    const hasAccess = selectedDistrict === 'Washington' || selectedDistrict === 'Fayette R-III' || superAdmin;

    return (
        <div className={`sidebar ${isMinimized ? 'minimized' : ''}`}>
            <div className="toggle-arrow" onClick={toggleSidebar}>
                {isMinimized ? '+' : '-'}
            </div>
            {!isMinimized && (
                <ul>
                    {/* Overview Category */}
                    <li className="category-title" onClick={toggleOverview}>
                        Overview {isOverviewOpen ? '-' : '+'}
                    </li>
                    {isOverviewOpen && (
                        <li
                            onClick={() => setActiveComponent('Overview')}
                            className={`sub-category ${activeComponent === 'Overview' ? 'active' : ''}`}
                        >
                            Overview
                        </li>
                    )}

                    {/* Historical Category */}
                    <li className="category-title" onClick={toggleHistorical}>
                        Historical {isHistoricalOpen ? '-' : '+'}
                    </li>
                    {isHistoricalOpen && (
                        <>
                            <li
                                onClick={() => setActiveComponent('Budget')}
                                className={`sub-category ${activeComponent === 'Budget' ? 'active' : ''}`}
                            >
                                Budget
                            </li>
                            <li
                                onClick={() => setActiveComponent('Faculty')}
                                className={`sub-category ${activeComponent === 'Faculty' ? 'active' : ''}`}
                            >
                                Faculty
                            </li>
                            <li
                                onClick={() => setActiveComponent('Students')}
                                className={`sub-category ${activeComponent === 'Students' ? 'active' : ''}`}
                            >
                                Students
                            </li>
                            <li
                                onClick={() => setActiveComponent('Academics')}
                                className={`sub-category ${activeComponent === 'Academics' ? 'active' : ''}`}
                            >
                                Academics
                            </li>
                            <li
                                onClick={() => setActiveComponent('Revenue')}
                                className={`sub-category ${activeComponent === 'Revenue' ? 'active' : ''}`}
                            >
                                Revenue
                            </li>
                            <li
                                onClick={() => setActiveComponent('Debt')}
                                className={`sub-category ${activeComponent === 'Debt' ? 'active' : ''}`}
                            >
                                Debt & Capital
                            </li>
                        </>
                    )}

                    {/* Current Category - Visible only for superAdmin or Washington or Fayette R-III */}
                    {hasAccess && (
                        <>
                            <li className="category-title" onClick={toggleCurrent}>
                                Current {isCurrentOpen ? '-' : '+'}
                            </li>
                            {isCurrentOpen && (
                                <li
                                    onClick={() => setActiveComponent('Monthly')}
                                    className={`sub-category ${activeComponent === 'Monthly' ? 'active' : ''}`}
                                >
                                    Monthly Report - Beta
                                </li>
                            )}
                        </>
                    )}

                    {/* Planning Category - Visible only for superAdmin or Washington or Fayette R-III */}
                    {hasAccess && (
                        <>
                            <li className="category-title" onClick={togglePlanning}>
                                Planning {isPlanningOpen ? '-' : '+'}
                            </li>
                            {isPlanningOpen && (
                                <>
                                <li
                                    onClick={() => setActiveComponent('Salary')}
                                    className={`sub-category ${activeComponent === 'Salary' ? 'active' : ''}`}
                                >
                                    Certified Schedule - Beta
                                </li>
                                <li
                                    onClick={() => setActiveComponent('SalaryClassified')}
                                    className={`sub-category ${activeComponent === 'SalaryClassified' ? 'active' : ''}`}
                                >
                                    Classified Schedule - Beta
                                </li>
                                {selectedDistrict==='Fayette R-III' &&(
                                    <>
                                    <li
                                    onClick={() => setActiveComponent('Transport')}
                                    className={`sub-category ${activeComponent === 'Transport' ? 'active' : ''}`}
                                >
                                    Transportation - Beta
                                </li>
                                </>
                                )}
                                {selectedDistrict==='Fayette R-III' &&(
                                    <>
                                    <li
                                    onClick={() => setActiveComponent('Staff')}
                                    className={`sub-category ${activeComponent === 'Staff' ? 'active' : ''}`}
                                >
                                    Staff - Beta
                                </li>
                                </>
                                )}
                                <li
                                onClick={() => setActiveComponent('Forecast')}
                                className={`sub-category ${activeComponent === 'Forecast' ? 'active' : ''}`}
                            >
                               Forecast - Beta
                            </li>
                            </>
                            )}
                        </>
                    )}

                    {/* Settings Category */}
                    {!boardView && (
                        <>
                            <li className="category-title" onClick={toggleSettings}>
                                Settings {isSettingsOpen ? '-' : '+'}
                            </li>
                            {isSettingsOpen && (
                                <li
                                    onClick={() => setActiveComponent('Peers')}
                                    className={`sub-category ${activeComponent === 'Peers' ? 'active' : ''}`}
                                >
                                    Peer Group
                                </li>
                            )}
                        </>
                    )}
                </ul>
            )}
        </div>
    );
};

export default Sidebar;
