import React, { useState, useCallback, useEffect } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set } from "firebase/database";
import CertifiedStaffManagementTable from "./CertifiedEmployeeTable";
import ClassifiedStaffManagementTable from "./ClassifiedEmployeeTable";
import ActivityCompensationTable from "./ActivityEmployeeTable";
import "../../styles/StaffManagement.css";

const formatDollars = (num) => `$${Math.round(num).toLocaleString('en-US')}`;

const NestedStaffManagementTable = ({
  fiscalYear,
  districtCode,
  lifeMonthly,
  ppoMonthly,
  medicareRate,
  certifiedRetirement,
  socialSecurityRate,
  peers
}) => {
  const functionCodes = [1111, 1131, 1151, 1191, 1193, 1221, 1251, 1281, 1311, 1321, 1331,1411, 1421, 2122, 2125, 2134, 2211, 2222, 2311, 2321, 2329, 2411, 2523, 2524, 2541, 2552, 2561, 3511, 3512];
  const buildingCodes = [1050, 3000, 4040, 0o00];
  const [functionLabels, setFunctionLabels] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFunctionLabels = async () => {
      const labels = {};
      try {
        await Promise.all(
          functionCodes.map(async (functionCode) => {
            const labelRef = ref(database, `functionCodeMap/${functionCode}`);
            const snapshot = await get(labelRef);
            if (snapshot.exists()) {
              labels[functionCode] = snapshot.val();
            } else {
              labels[functionCode] = `Function ${functionCode}`; // Fallback if label doesn't exist
            }
          })
        );
        setFunctionLabels(labels);
      } catch (error) {
        console.error("Error fetching function labels:", error);
      }
    };

    fetchFunctionLabels();
  }, [functionCodes]);


  const [aggregatedData, setAggregatedData] = useState(
    functionCodes.reduce((acc, functionCode) => {
      acc[functionCode] = {
        fte: 0,
        salary: 0,
        totalCost: 0,
        buildings: {}, // Initialize with an empty object for buildings
      };
      return acc;
    }, {})
  );
  const Spinner = () => (
    <div className="spinner">
      <div className="loading"></div>
    </div>
  );
  

  useEffect(() => {
    const fetchTotalsFromFirebase = async () => {
      setLoading(true); // Start loading spinner
      const updatedData = { ...aggregatedData }; // Start with current data
  
      try {
        for (const functionCode of functionCodes) {
          for (const buildingCode of buildingCodes) {
            const roles = ["certified", "classified", "activity"];
            for (const role of roles) {
              const fteRef = ref(
                database,
                `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/${role}/totals/fte`
              );
              const salaryRef = ref(
                database,
                `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/${role}/totals/salary`
              );
              const totalCostRef = ref(
                database,
                `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/${role}/totals/totalCost`
              );
  
              const [fteSnapshot, salarySnapshot, totalCostSnapshot] = await Promise.all([
                get(fteRef),
                get(salaryRef),
                get(totalCostRef),
              ]);
  
              const fte =
                role === "activity"
                  ? 0
                  : fteSnapshot.exists()
                  ? fteSnapshot.val()
                  : 0;
              const salary = salarySnapshot.exists() ? salarySnapshot.val() : 0;
              const totalCost = totalCostSnapshot.exists()
                ? totalCostSnapshot.val()
                : 0;
  
              if (!updatedData[functionCode]) {
                updatedData[functionCode] = {
                  fte: 0,
                  salary: 0,
                  totalCost: 0,
                  buildings: {},
                };
              }
              if (!updatedData[functionCode].buildings[buildingCode]) {
                updatedData[functionCode].buildings[buildingCode] = {
                  certified: { fte: 0, salary: 0, totalCost: 0 },
                  classified: { fte: 0, salary: 0, totalCost: 0 },
                  activity: { fte: 0, salary: 0, totalCost: 0 },
                };
              }
  
              updatedData[functionCode].buildings[buildingCode][role] = {
                fte,
                salary,
                totalCost,
              };
  
              const combinedBuildingTotals = {
                fte:
                  updatedData[functionCode].buildings[buildingCode].certified.fte +
                  updatedData[functionCode].buildings[buildingCode].classified.fte +
                  updatedData[functionCode].buildings[buildingCode].activity.fte,
                salary:
                  updatedData[functionCode].buildings[buildingCode].certified.salary +
                  updatedData[functionCode].buildings[buildingCode].classified.salary +
                  updatedData[functionCode].buildings[buildingCode].activity.salary,
                totalCost:
                  updatedData[functionCode].buildings[buildingCode].certified.totalCost +
                  updatedData[functionCode].buildings[buildingCode].classified.totalCost +
                  updatedData[functionCode].buildings[buildingCode].activity.totalCost,
              };
  
              updatedData[functionCode].buildings[buildingCode] = {
                ...updatedData[functionCode].buildings[buildingCode],
                ...combinedBuildingTotals,
              };
  
              const functionLevelTotals = Object.values(
                updatedData[functionCode].buildings
              ).reduce(
                (acc, building) => {
                  acc.fte += building.fte || 0;
                  acc.salary += building.salary || 0;
                  acc.totalCost += building.totalCost || 0;
                  return acc;
                },
                { fte: 0, salary: 0, totalCost: 0 }
              );
  
              updatedData[functionCode] = {
                ...updatedData[functionCode],
                ...functionLevelTotals,
              };
            }
          }
        }
  
        setAggregatedData(updatedData); // Update state with the new data
      } catch (error) {
        console.error("Error fetching data from Firebase:", error);
      } finally {
        setLoading(false); // End loading spinner only after all data is processed
      }
    };
  
    fetchTotalsFromFirebase();
  }, [fiscalYear, districtCode]); // Refetch data if fiscalYear or districtCode changes
  
  
  const [expandedFunctions, setExpandedFunctions] = useState({});
  const [expandedBuildings, setExpandedBuildings] = useState({});
  const [addingBuilding, setAddingBuilding] = useState(null); // To track if adding a building for a function

  const toggleFunctionExpand = (functionCode) => {
    setExpandedFunctions((prev) => ({
      ...prev,
      [functionCode]: !prev[functionCode],
    }));
    setAddingBuilding(null); // Reset adding state when toggling
  };

  const toggleBuildingExpand = (functionCode, buildingCode) => {
    setExpandedBuildings((prev) => ({
      ...prev,
      [`${functionCode}-${buildingCode}`]: !prev[`${functionCode}-${buildingCode}`],
    }));
  };

  const addBuilding = (functionCode, buildingCode) => {
    setAggregatedData((prevData) => {
      const updatedData = { ...prevData };
  
      // Initialize the functionCode if it doesn't already exist
      if (!updatedData[functionCode]) {
        updatedData[functionCode] = {
          fte: 0,
          salary: 0,
          totalCost: 0,
          buildings: {}, // Initialize with an empty buildings object
        };
      }
  
      // Initialize the buildingCode if it doesn't already exist
      if (!updatedData[functionCode].buildings[buildingCode]) {
        updatedData[functionCode].buildings[buildingCode] = {
          certified: { fte: 0, salary: 0, totalCost: 0 },
          classified: { fte: 0, salary: 0, totalCost: 0 },
          activity: { fte: 0, salary: 0, totalCost: 0 },
        };
      }
  
      return updatedData;
    });
  
    setAddingBuilding(null); // Reset the add building state
  };
  

const handleDataUpdate = useCallback(
  (functionCode, buildingCode, newData, type) => {
    setAggregatedData((prevData) => {
      const updatedData = { ...prevData };

      // Initialize functionCode if missing
      if (!updatedData[functionCode]) {
        updatedData[functionCode] = {
          fte: 0,
          salary: 0,
          totalCost: 0,
          buildings: {},
        };
      }

      // Initialize buildingCode if missing
      if (!updatedData[functionCode].buildings[buildingCode]) {
        updatedData[functionCode].buildings[buildingCode] = {
          certified: { fte: 0, salary: 0, totalCost: 0 },
          classified: { fte: 0, salary: 0, totalCost: 0 },
          activity: { fte: 0, salary: 0, totalCost: 0 },
        };
      }

      const currentBuilding = updatedData[functionCode].buildings[buildingCode];

      // Update specified type data
      currentBuilding[type] = {
        fte: newData.fte || 0,
        salary: newData.salary || 0,
        totalCost: newData.totalCost || 0,
      };

      // Calculate building totals
      const combinedBuildingTotals = {
        fte:
          currentBuilding.certified.fte +
          currentBuilding.classified.fte +
          currentBuilding.activity.fte,
        salary:
          currentBuilding.certified.salary +
          currentBuilding.classified.salary +
          currentBuilding.activity.salary,
        totalCost:
          currentBuilding.certified.totalCost +
          currentBuilding.classified.totalCost +
          currentBuilding.activity.totalCost,
      };

      updatedData[functionCode].buildings[buildingCode] = {
        certified: currentBuilding.certified,
        classified: currentBuilding.classified,
        activity: currentBuilding.activity,
        ...combinedBuildingTotals,
      };

      // Update function-level totals
      const functionLevelTotals = Object.values(updatedData[functionCode].buildings).reduce(
        (acc, building) => {
          acc.fte += building.fte || 0;
          acc.salary += building.salary || 0;
          acc.totalCost += building.totalCost || 0;
          return acc;
        },
        { fte: 0, salary: 0, totalCost: 0 }
      );

      updatedData[functionCode] = {
        ...updatedData[functionCode],
        ...functionLevelTotals,
      };

      return updatedData;
    });
  },
  []
);

  return (
    <div className="staff-nested-container">
    {loading ? (
      <Spinner />
    ) : (
    <table className="staff-management-table">
      <thead>
        <tr>
          <th>Function Code</th>
          <th>FTE</th>
          <th>Salary</th>
          <th>Total Cost</th>
        </tr>
      </thead>
      <tbody>
        {functionCodes.map((functionCode) => (
          <React.Fragment key={functionCode}>
            {/* Function Row */}
            <tr>
            <td style={{ textAlign: "left" }}>
                    <button
                      className="add-row-btn"
                      style={{ textAlign: "left"}}
                      onClick={() => toggleFunctionExpand(functionCode)}
                    >
                      {expandedFunctions[functionCode] ? "▼" : "▶"} {functionCode} - {functionLabels[functionCode] || `Function ${functionCode}`}
                    </button>
                  </td>
              <td>{aggregatedData[functionCode].fte.toFixed(2)}</td>
              <td>{formatDollars(aggregatedData[functionCode].salary)}</td>
              <td>{formatDollars(aggregatedData[functionCode].totalCost)}</td>
            </tr>
            {/* Render Add Building and Building Rows */}
            {expandedFunctions[functionCode] && (
              <>
                {/* Render Building Rows */}
                {Object.keys(aggregatedData[functionCode].buildings).map((buildingCode) => (
                  <React.Fragment key={`${functionCode}-${buildingCode}`}>
                    <tr>
                      <td style={{ paddingLeft: "2rem", textAlign: "left"}}>
                        <button className="add-row-btn" 
                                style={{ textAlign: "left"}}
                                onClick={() => toggleBuildingExpand(functionCode, buildingCode)}
                        >
                          {expandedBuildings[`${functionCode}-${buildingCode}`] ? "▼" : "▶"} Building: {buildingCode}
                        </button>
                      </td>
                      <td>
                        {(aggregatedData[functionCode]?.buildings[buildingCode]?.fte || 0).toFixed(2)}
                      </td>
                      <td>
                        {formatDollars(
                          aggregatedData[functionCode]?.buildings[buildingCode]?.salary || 0
                        )}
                      </td>
                      <td>
                        {formatDollars(
                          aggregatedData[functionCode]?.buildings[buildingCode]?.totalCost || 0
                        )}
                      </td>
                    </tr>

                    {/* Render tables for this building */}
                    {expandedBuildings[`${functionCode}-${buildingCode}`] && (
                      <tr>
                        <td colSpan={4} style={{ paddingLeft: "4rem" }}>
                          {["1411", "1421"].includes(functionCode.toString()) ? (
                            <ActivityCompensationTable
                              fiscalYear={fiscalYear}
                              districtCode={districtCode}
                              medicareRate={medicareRate}
                              certifiedRetirement={certifiedRetirement}
                              functionCode={functionCode}
                              buildingCode={buildingCode}
                              onDataUpdate={(newData) =>
                                handleDataUpdate(functionCode, buildingCode, newData, "activity")
                              }
                            />
                          ) : (
                            <>
                              <CertifiedStaffManagementTable
                                fiscalYear={fiscalYear}
                                districtCode={districtCode}
                                lifeMonthly={lifeMonthly}
                                ppoMonthly={ppoMonthly}
                                medicareRate={medicareRate}
                                certifiedRetirement={certifiedRetirement}
                                socialSecurityRate={socialSecurityRate}
                                functionCode={functionCode}
                                buildingCode={buildingCode}
                                onDataUpdate={(newData) =>
                                  handleDataUpdate(functionCode, buildingCode, newData, "certified")
                                }
                              />
                              <ClassifiedStaffManagementTable
                                fiscalYear={fiscalYear}
                                districtCode={districtCode}
                                lifeMonthly={lifeMonthly}
                                ppoMonthly={ppoMonthly}
                                medicareRate={medicareRate}
                                peers={peers}
                                socialSecurityRate={socialSecurityRate}
                                functionCode={functionCode}
                                buildingCode={buildingCode}
                                onDataUpdate={(newData) =>
                                  handleDataUpdate(functionCode, buildingCode, newData, "classified")
                                }
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}

                
              </>
            )}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
  <tr>
    <td><strong>Total</strong></td>
    <td>
      {functionCodes
        .reduce((total, functionCode) => total + aggregatedData[functionCode]?.fte || 0, 0)
        .toFixed(2)}
    </td>
    <td>
      {formatDollars(functionCodes
        .reduce((total, functionCode) => total + aggregatedData[functionCode]?.salary || 0, 0))}
    </td>
    <td>
      {formatDollars(functionCodes
        .reduce((total, functionCode) => total + aggregatedData[functionCode]?.totalCost || 0, 0))}
    </td>
  </tr>
</tfoot>

    </table>
    )}
  </div>
  );
};

export default NestedStaffManagementTable;

