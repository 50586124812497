import React, {useState, useEffect} from 'react';
import Card from './Card';
import '../styles/Dashboard.css';
import { database } from '../firebaseConfig'; // Ensure correct path
import { ref, get, set } from "firebase/database";
//import DrilldownTable from './DrilldownTable';
import ReportDrilldownTable from './ReportDrilldown';

const MonthlyReport = ({ selectedDistrictInfo, boardView, dashboardData, currentBudget }) => {
    const [highlights, setHighlights] = useState([]);
    const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];

    // Firebase reference
    const highlightsRef = ref(database, `highlights/${districtCode}`);

    useEffect(() => {
        // Load highlights from Firebase or write defaults
        const loadOrInitializeHighlights = async () => {
            try {
                const snapshot = await get(highlightsRef);
                if (snapshot.exists()) {
                    setHighlights(snapshot.val());
                } else {
                    await writeDefaultHighlights(); // Write defaults if no data exists
                }
            } catch (error) {
                console.error("Error fetching highlights, initializing defaults:", error);
                await writeDefaultHighlights(); // Write defaults in case of error
            }
        };

        // Function to write default highlights to Firebase
        const writeDefaultHighlights = async () => {
            const defaultHighlights = [
                "Insert & edit highlights by clicking here...",
                "Add a new row by hitting \"enter\""
            ];
            try {
                setHighlights(defaultHighlights); // Update local state
                await set(highlightsRef, defaultHighlights); // Save to Firebase
            } catch (writeError) {
                console.error("Error writing default highlights to Firebase:", writeError);
            }
        };

        loadOrInitializeHighlights();
    }, [districtCode]); // Reload when the district changes

    const saveHighlights = async (newHighlights) => {
        try {
            await set(highlightsRef, newHighlights);
        } catch (error) {
            console.error("Error saving highlights:", error);
        }
    };

    const handleHighlightChange = (index, value) => {
        const newHighlights = [...highlights];
        newHighlights[index] = value;
        setHighlights(newHighlights);
        saveHighlights(newHighlights); // Save to Firebase
    };

    const handleKeyPress = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const newHighlights = [...highlights];
            newHighlights.splice(index + 1, 0, ""); // Add a new row
            setHighlights(newHighlights);
            saveHighlights(newHighlights); // Save to Firebase
        } else if (e.key === 'Backspace' && highlights[index] === "") {
            e.preventDefault();
            const newHighlights = [...highlights];
            newHighlights.splice(index, 1); // Remove the empty row
            setHighlights(newHighlights);
            saveHighlights(newHighlights); // Save to Firebase
        }
    };

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

    const desc = 
        selectedDistrictInfo['DISTRICT_NAME'] === "Fayette R-III"
            ? "Current Budget" 
            : selectedDistrictInfo['DISTRICT_NAME'] === "Washington"
                ? "Current Budget (Upload Current Data to View)" 
                : "Current Budget (Upload Current Data to View)";
    

    function calculateAverage(array) {
        if (array.length === 0) {
          return 0;
        }
      
        const sum = array.reduce((acc, num) => acc + num, 0);
        return sum / array.length;
    }

    selectedDistrictInfo['peer_TEACHSALARY_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['TEACHER_SALARY_AVG_REG_TERM']));
    selectedDistrictInfo['peer_STUDENTTEACHERRATIO_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['STUDENTS_PER_TEACHER_RATIO']));
    selectedDistrictInfo['peer_STUDENTADMINRATIO_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['STUDENTS_PER_ADMIN_RATIO']));
    selectedDistrictInfo['peer_PROFDEVEL_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT']));


    return (
        <div className="dashboard">
            <div className="title" style={{fontSize:'40px'}}>October Board Budget Report</div>

            <div className="dashboard-cards">
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Total Fund Balance"
                    value={
                        selectedDistrictInfo['DISTRICT_NAME'] === 'Fayette R-III' 
                            ? "59%*" 
                            : selectedDistrictInfo['DISTRICT_NAME'] === 'Washington' 
                                ? "57%" 
                                : "%X"
                    }
                    YoY=""
                    smallText=""
                    bgColor="#88b0d8"
                    arrowDirection=""
                    definition="Percent of the total annual budget that can be paid with cash on hand"
                    graphMetric="TEACHER_SALARY_AVG_REG_TERM"
                    yAxisTitle='Dollars ($)'
                    boardView={boardView}
                    graphData={dashboardData}
                    isExpandable={false}
                />
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Cash On Hand" 
                    value= {
                        selectedDistrictInfo['DISTRICT_NAME'] === 'Fayette R-III' 
                            ? "$5.1m*" 
                            : selectedDistrictInfo['DISTRICT_NAME'] === 'Washington' 
                                ? "$47m" 
                                : "%X"
                    }
                    YoY=""
                    smallText=""
                    bgColor="#d8abc1"
                    arrowDirection=""
                    definition="The amount cash available in our bank accounts"
                    graphMetric="STUDENTS_PER_TEACHER_RATIO"
                    yAxisTitle='Ratio'
                    boardView={boardView}
                    graphData={dashboardData}
                    isExpandable={false}
                />
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="YTD Expenses" 
                    value="31%"
                    YoY=""
                    smallText=""
                    bgColor="#87b6a7"
                    arrowDirection=""
                    definition="Percent of our total annual budget that has been spent year to date"
                    graphMetric="STUDENTS_PER_ADMIN_RATIO"
                    yAxisTitle='Ratio'
                    boardView={boardView}
                    graphData={dashboardData}
                    isExpandable={false}
                />
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="YTD Revenue %" 
                    value="19%"
                    YoY=""
                    smallText=""
                    bgColor="#f79f79"
                    arrowDirection=""
                    definition="Percent of our annual revenue that has been received year to date"
                    graphMetric="BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT"
                    yAxisTitle='Percent (%)'
                    boardView={boardView}
                    graphData={dashboardData}
                    isExpandable={false}
                />
            </div>
            <div>
            <div className="key-highlights">
                    <h1 className="title">Key Highlights</h1>
                    <ul>
                        {highlights.map((highlight, index) => (
                            <li key={index} style={{ fontSize: '25px', marginBottom: '1%', marginLeft: '5%' }}>
                                <input
                                    type="text"
                                    value={highlight}
                                    onChange={(e) => handleHighlightChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyPress(e, index)}
                                    style={{
                                        fontSize: '25px',
                                        width: '90%',
                                        padding: '5px',
                                        border: 'none',
                                        outline: 'none',
                                        background: 'transparent'
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                </div>

            {/* Table Integration */}
          <h1 className="title">{desc}</h1>
          <div className="drilldownTableContainer" style={{marginBottom:"15%"}}>
            <ReportDrilldownTable 
              data={currentBudget} 
              year={"2025"} 
              showGraph={false} 
              selectedSchool={selectedDistrictInfo['DISTRICT_NAME']}
              showGraphOption={false}
            />
          </div>
            </div>
        </div>
        
    );
};

export default MonthlyReport;

