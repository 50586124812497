import React, { useState, useEffect } from 'react';
import { database } from '../firebaseConfig';
import { ref, update, get } from "firebase/database";
import '../styles/GeneralDrilldownTable.css'
import LineGraph from './LineGraph';
import {getUniqueValues, getShade, arrToJSON, formatDollars} from '../utils'

// This function creates an expandable hierarchical table and expects the following from the data:
//
//   1) It is an array of JSON objects of the form [{'rowKey': value, 'columnKey': value, 'valueLabel': value, 'PARENT': value, 'LEVEL': value}]
//   2) The values can be numerical or strings for the rowKey, columnKey, and valueLabel. PARENT is expected to be a string...
//          ...corresponding to the parent object with rowKey === PARENT. LEVEL is an integer describing the level in the hierarchy...
//          ...of the LABEL. For example, if the rowKey's PARENT === 'Self', its level should be 0 indicating it is the top-most...
//          ...level in the hierarchy. The children of that rowKey should have level 1, the children of that level 2, etc.
//   3) Sometimes we only want to show a subset of the data in the table. For that, we can apply tableDataFilters...
//          ... that way we preserve all of the data for other functionality like graphs.
//   4) The table values may need formatted for the table but not for the graph. This is common in the case of...
//          ... dollar amounts. For this tableDataFormats can be specified like {'TOTAL_ALL_FUNDS': 'dollars'}

const GeneralDrilldownTable = ({data, tableDataFilters, tableDataFormats, rowKey, columnKey, valueLabel, rowLevelFunctions, fiscalYear}) => {

    // filter the tableData if the uesr has specified tableDataFilters
    const filterFunction = new Function('item', `return ${tableDataFilters};`);
    let tableData = tableDataFilters? data.filter(filterFunction) : data;
    
    // format the table data if the user has specified tableDataFormats
    let formattedTableData;
    if(tableDataFormats){
      switch(tableDataFormats[valueLabel]) {
        case 'dollars':
          formattedTableData = tableData.map(item => ({
            ...item,
            [valueLabel]: formatDollars(item[valueLabel]),
          }));
        break;
      }
    }

    const graphAvailable = rowLevelFunctions['graph'];
    const editAvailable = rowLevelFunctions['edit'];

    const graphData = data;

    // By default we expand only rows where PARENT == 'Self'
    const defaultExpandedRows = getUniqueValues(tableData.filter(item => item.PARENT==='Self'), rowKey);

    const [expandedRows, setExpandedRows] = useState(defaultExpandedRows);
    const [areChildrenExpanded, setAreChildrenExpanded] = useState(arrToJSON(defaultExpandedRows))
    const [selectedGraphLabel, setSelectedGraphLabel] = useState(null);
    const [selectedTableLabel, setSelectedTableLabel] = useState(null);
    const [viewMode, setViewMode] = useState('graph');
    const [editedValues, setEditedValues] = useState({});

    ////////////////////////// FUNCTIONS /////////////////////////////

    const handleShowGraphClick = (label) => {
      setSelectedGraphLabel((prevLabel) => (prevLabel === label ? null : label));
    };

    const handleShowTableClick = (label) => {
      setSelectedTableLabel((prevLabel) => (prevLabel === label ? null : label));
    };

    // Handle view mode toggle (graph or raw data)
    const toggleViewMode = () => {
      setViewMode(viewMode === 'graph' ? 'spreadsheet' : 'graph');
    };

    const isCellEditable = (row, col) => {
      return row['IS_EDITABLE']? row['IS_EDITABLE'].includes(col) : false;
    }

    // Handler for input change
    const handleInputChange = (row, label, col, value) => {
      row[col] = value;
      setEditedValues(prev => ({
        ...prev,
        [`${label}-${col}`]: parseFloat(value),
        [`${label}-${'TOTAL_ALL_FUNDS'}`]: parseFloat(parseFloat(row['GENERAL_FUND']) +
                                           parseFloat(row['SPECIAL_REVENUE']) + 
                                           parseFloat(row['CAPITAL_PROJECTS']) + 
                                           parseFloat(row['DEBT_SERVICE']))
      }));
    };

    // Handler for input blur (when user clicks out)
    async function handleInputBlur(row, col) {
      // first save the new value of the object that was edited
      const keyTotalRef = ref(
        database,
        `currentBudgetBeta/${row['ID']}/${row['YEAR']}/${row['functionCode'].substring(0,4)}/${row['accountCode']}`
      );
      const key = `${row['functionCode'].substring(0,4)}-${row['accountCode']}-${col}`
      update(keyTotalRef, { [col]: parseFloat(editedValues[key]) || 0 })
        .then(() => console.log('update complete'))
        .catch(() => console.log('update error'));

      // update TOTAL_ALL_FUNDS to be equal to the sum of the four funds
      get(keyTotalRef).then(result => {
        const json = result.val()
        const total_all_funds = json['GENERAL_FUND'] + json['SPECIAL_REVENUE'] + json['DEBT_SERVICE'] + json['CAPITAL_PROJECTS'];
        update(keyTotalRef, {'TOTAL_ALL_FUNDS':parseFloat(total_all_funds) || 0})
            .then(() => {
              // update the parent TOTAL_ALL_FUNDS to be the sum of the children
              let parentRef = ref(
                database,
                `currentBudgetBeta/${row['ID']}/${row['YEAR']}/${row['functionCode'].substring(0,4)}`
              );
                     
              get(parentRef).then(result => {
                let parentSum = 0;
                Object.keys(result.val()).forEach(key=>parentSum+=result.val()[key].TOTAL_ALL_FUNDS)

                tableData.forEach(item=>{
                  if((item.functionCode===row['functionCode'])&&(item.YEAR===fiscalYear)){
                    item.TOTAL_ALL_FUNDS = parseFloat(parentSum);
                  }
                })
              })
                
            
            })
            .then(() => {
              // update the grandparent TOTAL_ALL_FUNDS to be the sum of its children
              let parentName = tableData.filter(item=>item.functionCode===row['functionCode'])[0]['PARENT'];
              
              let children = [...new Set(tableData.filter(item=>(item.PARENT===parentName)&&(item.LABEL)).map(item=>item.LABEL))];

              console.log('parent: ', parentName, 'children: ', children)

            })
            .catch(() => console.log('update error'));
      })

      

    };

    const showTable = (data, columns, year) => (
      <tr>
        <td colSpan={7} style={{ padding: '20px', position: 'relative' }}>
      <table style={{
                borderCollapse: "collapse", // Ensures borders remain collapsed
                width: "100%",
                margin: "0px", // Adds space around the table
              }}>
        <thead>
          <tr>
            {
            columns.map((col) => (
              <th
                key={col}
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.filter(item => item.YEAR===year).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col) => (
                <td
                  key={col}
                  style={{ border: "1px solid black", padding: "8px" }}
                >
                  {isCellEditable(row, col) ? (
                      <input
                        type="text"
                        value={
                          editedValues[`${row['functionCode'].substring(0,4)}-${row['accountCode']}-${col}`] !== undefined
                            ? editedValues[`${row['functionCode'].substring(0,4)}-${row['accountCode']}-${col}`]
                            : row[col]
                        }
                        onChange={(e) => handleInputChange(row, `${row['functionCode'].substring(0,4)}-${row['accountCode']}`, col, e.target.value)}
                        onBlur={() => handleInputBlur(row, col)}
                        style={{
                          width: '100%',
                          padding: '4px',
                          boxSizing: 'border-box',
                        }}
                      />
                    ) :
                      editedValues[`${row['functionCode'].substring(0,4)}-${row['accountCode']}-${col}`] !== undefined
                      ? editedValues[`${row['functionCode'].substring(0,4)}-${row['accountCode']}-${col}`]
                      : typeof row[col] === "number"? formatDollars(row[col]) : row[col]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      </td>
      </tr>
    );

    const showGraph = (graphData, label) => (
      <tr>
        <td colSpan={5} style={{ padding: '20px', position: 'relative' }}>
          <div style={{ width: '100%', height: '500px', overflow: 'hidden', position: 'relative' }}>
            {viewMode === 'graph' ? (
              <div style={{ width: '100%', height: '90%' }}>
                <LineGraph
                  data={graphData}
                  yAxis="TOTAL_ALL_FUNDS"
                  xAxis="YEAR"
                  groupLabel="DISTRICT_NAME"
                  yAxisLabel="Dollars ($)"
                  graphTitle={label}
                />
              </div>
            ) : (
              <div>
                <table style={{ backgroundColor: '#eee', border: '10px solid black', margin: '10px auto' }}>
                  <thead>
                    <tr key={label}>
                      <th style={{ textAlign: 'center', padding: '10px' }}>Year</th>
                      {tableHeaders.filter(label => label!='').map((column) => (
                        <th key={column} style={{ textAlign: 'center', padding: '10px' }}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  {/* Make body of the table that displays the graph data */}
                  <tbody>
                    {getUniqueValues(graphData, 'YEAR').map((row_item, row_index) => (
                      <tr key={row_index}>
                        <td key={'year_label'} align="center">
                          {row_item}
                        </td>
                        {graphData.filter(rows => rows['YEAR'].includes(row_item)).map((row_item, index) => (
                          <td key={index}
                              align="center">
                            {formatDollars(row_item[valueLabel])}
                          </td>
                          ))
                        } 
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <button onClick={toggleViewMode} style={{ position: 'absolute', bottom: '5px', right: '10px' }}>
            {viewMode === 'graph' ? 'View Raw Data' : 'View Graph'}
          </button>
        </td>
      </tr>      
    )
  
    const toggleExpand = (label) => {
      const children = getUniqueValues(tableData.filter(item => item.PARENT === label), rowKey);
      // if the children are not already in the list of expandedRows, add them, otherwise remove them
      setExpandedRows((prev) => {
        const parentIndex = prev.indexOf(label);
        if (!children.every(element => prev.includes(element))) {
          // Insert children immediately after the parent
          const updatedRows = [...prev];
          updatedRows.splice(parentIndex + 1, 0, ...children);

          // update this JSON object to note that this element's children are expanded
          setAreChildrenExpanded((prev) => {
            const prevCopy = {...prev};
            prevCopy[label] = true;
            return prevCopy
          })
          
          return updatedRows;

        } else {
          // update this JSON object to note that this element's children are no longer expanded
          setAreChildrenExpanded((prev) => {
            const prevCopy = {...prev};
            prevCopy[label] = false;
            return prevCopy
          })

          // Remove children from expandedRows
          return prev.filter(row => !children.includes(row));
        }
      });
    };

    function renderRow(tableData, graphData, label, columnNames, row_index){
        let rowData = tableData.filter(data_item => (data_item[rowKey] === label)&(columnNames.includes(data_item[columnKey])));
        const isExpandable = tableData.filter(entry => entry.PARENT === label).length > 0; // if item has children, it's expandable
        const isExpanded = areChildrenExpanded[label];

        return (
          <React.Fragment key={row_index}>
            <tr>
            {/* First column is a text label that may or may not have an expansion arrow */}
            <td
                style={{
                  cursor: 'pointer',
                  backgroundColor: getShade(rowData[0].LEVEL),
                  paddingLeft: `${rowData[0].LEVEL * 20}px`,
                  maxWidth: '200px'
                }}>
            {isExpandable ? ( // if the label is expandable, put the arrow
              <span onClick={() => toggleExpand(label)}
                    style={{display: 'inline-block',
                            transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
                            transition: 'transform 0.15s',
                            marginRight: '5px',
                            marginLeft: '5px'}}> ▶ </span>
            ) : ( // otherwise, put a dash
              <span> - </span>
            )}
            <span onClick={() => toggleExpand(label)}>{label}</span>
            {graphAvailable && (<span
                  role="img"
                  aria-label="Show Graph"
                  style={{ cursor: 'pointer', marginLeft: '3px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowGraphClick(label);
                  }}
                >
                  📈
            </span>)}
            {editAvailable && !isExpandable && (<span
                  role="img"
                  aria-label="Show Table"
                  style={{ cursor: 'pointer', marginLeft: '3px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowTableClick(label);
                  }}
                >
                  ✎
            </span>)}
            </td>
            
            {/* All other columns are data */}
            {rowData.map((item, index)=> (
                <td key={index}
                    align="center"
                    style={{
                      backgroundColor: getShade(rowData[0].LEVEL)
                    }}>
                  {item[valueLabel]}
                </td>
            ))}
            </tr>
            {/* Show the graph if the graph button has been clicked */}
            {selectedGraphLabel === label && showGraph(graphData.filter(item => item[rowKey] === label), label)}
            {selectedTableLabel === label && showTable(graphData.filter(item => (item[rowKey] === label)&(item['DESCRIPTION']!='Aggregate')), 
                                                       ["accountCode", "DESCRIPTION", "CAPITAL_PROJECTS", "GENERAL_FUND", "SPECIAL_REVENUE", "DEBT_SERVICE", "TOTAL_ALL_FUNDS"],
                                                       fiscalYear)}
            </React.Fragment>
        )
    }
    ///////////////////////////////////////////////////////////////

    // get the labels for the columns of the table
    const tableHeaders = getUniqueValues(tableData, columnKey);

    tableHeaders.unshift('') // Add header for the first column
    
    return (
    <table className="table">
      <thead>
        {/* column labels */}
         <tr>
            {tableHeaders.map((header, index) => (
                <th key={index}>{header}</th>
            ))}
        </tr>
      </thead>
      {/* table body */}
      <tbody>
        {/* display all currently expanded rows */}
        {expandedRows.map((label,index) => (
            renderRow(formattedTableData, graphData, label, tableHeaders, index)
        ))}
      </tbody>
    </table>
    )
};

export default GeneralDrilldownTable;