import React, { useState, useEffect } from 'react';
import { database } from '../firebaseConfig';
import { ref, get } from "firebase/database";
import ForecastTableNew from './ForecastTableNew';
import '../styles/ForecastDash.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title,annotationPlugin);

const ForecastDash = ({ selectedDistrictInfo, boardView, dashboardData, currentBudget }) => {
    const [expenseData, setExpenseData] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [surpDefData, setSurpDefData] = useState([]);
    const [drilldownData, setDrilldownData] = useState(null);
    const [isGraphVisible, setIsGraphVisible] = useState(true);

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));
    const maxYearM1 = maxYear - 1;

    function expandArray(data, functionCodeMap) {
        const result = [];
        data.forEach((item) => {
          // Iterate over the keys of each object
          for (const key in item) {
            // Check if the key is not 'DISTRICT_COUNTY_CODE' or 'YEAR'
            if (key !== 'DISTRICT_COUNTY_CODE' && key !== 'YEAR' && key !== 'DISTRICT_NAME') {
              // Push a new object to the result array with the desired structure
              result.push({
                DISTRICT_NAME: item.DISTRICT_NAME,
                YEAR: item.YEAR,
                LABEL: functionCodeMap[key]? `${functionCodeMap[key]['LABEL']}` : key,
                PARENT: item[key].PARENT,
                TOTAL_ALL_FUNDS: item[key].TOTAL_ALL_FUNDS,
                CAPITAL_PROJECTS: item[key].CAPITAL_PROJECTS,
                SPECIAL_REVENUE: item[key].SPECIAL_REVENUE,
                GENERAL_FUND: item[key].GENERAL_FUND,
                DEBT_SERVICE: item[key].DEBT_SERVICE
              });
            }
          }
        });
      
        return result;
      }

    // Fetch drilldown data
    useEffect(() => {
        const fetchDrilldownData = async () => {
            try {
                const drilldownDataPromises = [selectedDistrictInfo['DISTRICT_COUNTY_CODE']].map(async (ID) => {
                    const snapshot = await get(ref(database, `/expenseDrilldown_dev/${ID}`));
                    return [ID, snapshot.exists() ? snapshot.val() : null];
                });
    
                const drilldownResults = await Promise.all(drilldownDataPromises);
                const drilldownDataJSON = Object.fromEntries(drilldownResults.filter(([_, data]) => data !== null));
    
                const drilldownDataArray = Object.entries(drilldownDataJSON).flatMap(([key, values]) => 
                    Object.entries(values).map(([YEAR, details]) => ({
                      DISTRICT_COUNTY_CODE: key,
                      YEAR,
                      ...details
                    }))
                  );
    
                for(let i = 0; i<drilldownDataArray.length; i++){
                    drilldownDataArray[i]['DISTRICT_NAME'] = drilldownDataArray[i]['1111']['DISTRICT_NAME'];
                }
    
                const functionCodeMapSnapshot = await get(ref(database, '/functionCodeMap_dev'));
                let functionCodeMap = functionCodeMapSnapshot.val();
    
                const ddData = expandArray(drilldownDataArray, functionCodeMap);
               
                setDrilldownData(ddData);
                
            } catch (error) {
                console.error('Error fetching drilldown data:', error);
            }
        };

        fetchDrilldownData();
    }, []);

    useEffect(() => {
        if(drilldownData){
            const currentBudgetExpenses = currentBudget.filter(item=>item.functionCode==='Expenses').map(item=>item.TOTAL_ALL_FUNDS);
            const drilldownDataExpenses = drilldownData.filter(item=>(item.LABEL==='Expenses')&&(item.YEAR > 2021)).map(item=>item.TOTAL_ALL_FUNDS);
            let exp = drilldownDataExpenses.concat(currentBudgetExpenses);
            setExpenseData(exp);

            const currentBudgetRevenue = currentBudget.filter(item=>item.functionCode==='Revenue').map(item=>item.TOTAL_ALL_FUNDS);
            const drilldownDataRevenue = drilldownData.filter(item=>(item.LABEL==='Revenue')&&(item.YEAR > 2021)).map(item=>item.TOTAL_ALL_FUNDS);
            let rev = drilldownDataRevenue.concat(currentBudgetRevenue);
            setRevenueData(rev);
            
            let surpDef = [];
            for(let i=0; i<drilldownDataRevenue.concat(currentBudgetRevenue).length; i++){
                surpDef.push(rev[i]-exp[i])
            }
            setSurpDefData(surpDef);
        }
    }, [drilldownData])

    const dollarsChartData = {
        labels: ['2022', '2023', '2024', '2025', '2026'],
        datasets: [
            {
                label: 'Expenses',
                data: expenseData,
                fill: false,
                tension: 0.1,
                borderColor: 'red',
                backgroundColor: 'red',
            },
            {
                label: 'Revenue',
                data: revenueData,
                fill: false,
                tension: 0.1,
                borderColor: 'blue',
                backgroundColor: 'blue',
            },
            {
                label: 'Surplus/Deficit',
                data: surpDefData,
                fill: false,
                tension: 0.1,
                borderColor: 'green',
                backgroundColor: 'green',
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Revenue/Expenses',
                font: {
                    size: 30,
                },
            },
            annotation: {
                annotations: {
                    forecastLine: {
                        type: 'line',
                        xMin: '2026',
                        xMax: '2026',
                        borderColor: 'rgba(0, 0, 0, 0.7)',
                        borderWidth: 2,
                        borderDash: [5, 5],
                        label: {
                            content: 'Forecast Begins',
                            enabled: true,
                            position: 'end',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            color: '#fff',
                        },
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Fiscal Year',
                    font: {
                        size: 22,
                    },
                },
                ticks: {
                    font: {
                        size: 20,
                    },
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Dollars (MM$)',
                    font: {
                        size: 22,
                    },
                },
                ticks: {
                    font: {
                        size: 20,
                    },
                },
            },
        },
    };

    return (
        <div className='forecast-dash-container'>
            <div 
                className='toggle-arrow' 
                onClick={() => setIsGraphVisible(!isGraphVisible)} 
            >
                {isGraphVisible ? '▼ Hide Graphs' : '► Show Graphs'}
            </div>
            
            
            {isGraphVisible && (
                <div className='graph-container'>
                    <div className='forecast-graph'>
                        <Line data={dollarsChartData} options={options} />
                    </div>
                    {/*
                    <div className='fund-balance-graph'>
                        <Line data={fundBalanceChartData} options={fundBalanceOptions} />
                    </div>
                    */}
                </div>
            )}
            
            <div className="forecast-table">
                {drilldownData ? (
                    <ForecastTableNew
                        pastBudgets={drilldownData}
                        currentBudget={currentBudget}
                        currentYear={"2026"}
                        selectedSchool={selectedDistrictInfo['DISTRICT_NAME']}
                    />
                ) : (
                    <p>Loading data...</p>
                )}
            </div>
            <div>
                <p>*Still being developed to include fund specific expenses to calculate fund balance</p>
            </div>
        </div>
    );
};

export default ForecastDash;
