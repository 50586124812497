import React, { useState } from "react";
import NestedStaffManagementTable from "./NestedStaffManagementTable";
import "../../styles/StaffManagement.css";

const StaffManagementTable = ({ selectedDistrictInfo, data }) => {
  const districtCode = selectedDistrictInfo["DISTRICT_COUNTY_CODE"];
  const [lifeMonthly, setLifeMonthly] = useState(3.5);
  const [ppoMonthly, setPpoMonthly] = useState(560);
  const [medicareRate, setMedicareRate] = useState(0.0145);
  const [certifiedRetirement, setCertifiedRetirement] = useState(0.145);
  const [socialSecurityRate, setSocialSecurityRate] = useState(0.062);
  const [peers, setPeers] = useState(0.0686);

  const fiscalYear = "2026";

  return (
    <div className="staff-management-container">
    <h1>Staff Management</h1>
      <div className="inputs-card">
        <div className="inputs-container">
          <div>
            <label>Life Monthly:</label>
            <input
              type="number"
              value={lifeMonthly}
              onChange={(e) => setLifeMonthly(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div>
            <label>PPO Monthly:</label>
            <input
              type="number"
              value={ppoMonthly}
              onChange={(e) => setPpoMonthly(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div>
            <label>Medicare Rate:</label>
            <input
              type="number"
              value={medicareRate}
              onChange={(e) => setMedicareRate(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div>
            <label>Certified Retirement:</label>
            <input
              type="number"
              value={certifiedRetirement}
              onChange={(e) => setCertifiedRetirement(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div>
            <label>Social Security Rate:</label>
            <input
              type="number"
              value={socialSecurityRate}
              onChange={(e) => setSocialSecurityRate(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div>
            <label>PEERS Rate:</label>
            <input
              type="number"
              value={peers}
              onChange={(e) => setPeers(parseFloat(e.target.value) || 0)}
            />
          </div>
        </div>
      </div>
          {/* Render tables */}
      <div className="tables-container">
        <NestedStaffManagementTable
          fiscalYear={fiscalYear}
          districtCode={districtCode}
          lifeMonthly={lifeMonthly}
          ppoMonthly={ppoMonthly}
          medicareRate={medicareRate}
          certifiedRetirement={certifiedRetirement}
          socialSecurityRate={socialSecurityRate}
          peers={peers}
        />
      </div>
    </div>
          )}

export default StaffManagementTable;
