import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import '../styles/LineGraph.css';

Chart.register(...registerables);

const colors = [
    '#495867', // Deep Gray-Blue
    '#f79f79', // Soft Coral
    '#f7d08a', // Warm Sand
    '#87b6a7', // Muted Teal
    '#d8abc1', // Dusty Pink
    '#88b0d8', // Light Blue
    '#b0c7a5', // Sage Green
    '#f0a1a8', // Light Coral
    '#b9a2d8', // Soft Lavender
    '#a5c4c9'  // Cool Light Blue
  ];

const formatYAxisLabel = (label) => {
    return label
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

// data is an array of JSON objects of the form:
// data = [{DISTRICT_NAME: 'DISTRICT1', YEAR: '2023', BUDGET: 123456}{}{}]
// In this example, the groupLabel is DISTRICT_NAME so different colored lines will appear for every unique DISTRICT_NAME
// xAxis is YEAR - this is the variable that will be shown on the x axis
// yAxis is BUDGET - this is the variable that will be shown on the y axis
// Sometimes, the order in which the "groups" (i.e. groupLabel) are displayed matters. The onus is on the user to
//     supply the data array sorted in the order they wish the groups to be displayed.

const LineGraph = ({ data, yAxis, xAxis, groupLabel, showLegend, yAxisLabel = '', graphTitle = '' }) => {
    const groups = [...new Set(data.map(item => item.DISTRICT_NAME))];
    const xAxisData = [...new Set(data.map(item => item[xAxis]))].sort();

    // Set the default selected group to the first group in the list
    const [selectedGroupItems, setSelectedGroupItems] = useState([groups[0]]);

    const handleCheckboxChange = (item) => {
        setSelectedGroupItems(prevSelected => {
            const isSelected = prevSelected.includes(item);
            return isSelected
                ? prevSelected.filter(s => s !== item) // Remove if unchecked
                : [...prevSelected, item]; // Add to selected list if checked
        });
    };

    // Assign colors based on groups to maintain consistent color order in legend
    const colorMap = groups.reduce((acc, item, index) => {
        acc[item] = colors[index % colors.length];
        return acc;
    }, {});

    // Prepare datasets with consistent colors and ordered by groups
    const datasets = groups
        .filter(group_item => selectedGroupItems.includes(group_item)) // Only include selected group items
        .map((group_item) => {
            const metricData = xAxisData.map(xVal => {
                const item = data.find(d => d[groupLabel] === group_item && d[xAxis] === xVal);
                return item ? parseFloat(item[yAxis]) : 0;
            });

            return {
                label: group_item,
                data: metricData,
                borderColor: colorMap[group_item], // Consistent color based on groups
                lineWidth: 3,
                fill: false,
                tension: 0.2,
                pointStyle: 'circle',
                pointRadius: 4,
                pointBackgroundColor: colorMap[group_item]
            };
        });

    const chartData = {
        labels: xAxisData,
        datasets: datasets,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: showLegend,
                position: 'right',
                labels: {
                    usePointStyle: true,
                    font: { size: 16 },
                    boxWidth: 20,
                    boxHeight: 20,
                    padding: 20,
                },
            },
            title: {
                display: true,
                text: graphTitle || formatYAxisLabel(yAxis),
                font: { size: 28 },
                padding: { top: 20, bottom: 30 },
            },
        },
        scales: {
            x: {
                ticks: { font: { size: 28 } },
                title: { display: true, font: { size: 28 }, text: 'Year' },
            },
            y: {
                beginAtZero: true,
                ticks: { font: { size: 28 } },
                title: { display: !!yAxisLabel, text: yAxisLabel || '', font: { size: 28 } },
            },
        },
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', height: '100%' }}> {/* Fixed height for container */}
            <div className="group-selection">
                {groups.map(group_item => (
                    <label key={group_item} style={{ display: 'block', marginBottom: '8px' }}>
                        <input
                            type="checkbox"
                            checked={selectedGroupItems.includes(group_item)}
                            onChange={(e) => handleCheckboxChange(group_item)}
                        />
                        {group_item}
                    </label>
                ))}
            </div>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default LineGraph;
