import React, { useState, useEffect, useMemo } from 'react';
import '../styles/Card.css';
import LineGraph from './LineGraph';
import Table from './Table';

const Card = ({
  defaultSchool,
  title,
  value,
  summaryText,
  smallText,
  bgColor,
  arrowDirection,
  definition,
  graphMetric,
  YoY,
  yAxisTitle = '',
  boardView,
  graphData,
  isExpandable=true,
}) => {

  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [schoolData, setSchoolData] = useState([]);
  const [viewMode, setViewMode] = useState('graph');
  const [transformedTableData, setTransformedTableData] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([defaultSchool['DISTRICT_NAME']]);
  const [isBoardView, setIsBoardView] = useState(boardView);

  const toggleExpand = () => {
    if (isExpandable) setExpanded(!expanded); // Prevent expanding if isExpandable is false
  };

  const toggleViewMode = (e) => {
    e.stopPropagation();
    setViewMode(viewMode === 'graph' ? 'spreadsheet' : 'graph');
  };

  const formatPrecision = (num, precision) => {
    return num ? parseFloat(num).toFixed(precision) : 'N/A';
  };

  function sortByDistrictName(data, name) {
    return data.sort((a, b) => {
        // Place elements with DISTRICT_NAME === name at the beginning
        if (a.DISTRICT_NAME === name && b.DISTRICT_NAME !== name) {
            return -1; // a comes first
        }
        if (a.DISTRICT_NAME !== name && b.DISTRICT_NAME === name) {
            return 1; // b comes first
        }
        // Maintain original order for others
        return 0;
    });
  }

  // Data transformation functions
  function transformGraphData(input) {
    const result = [];
    for (const [countyDistrictCode, years] of Object.entries(input)) {
      for (const [year, data] of Object.entries(years)) {
        const transformedObject = {
          YEAR: parseInt(year, 10),
          COUNTY_DISTRICT_CODE: parseInt(countyDistrictCode, 10),
          ...data
        };
        result.push(transformedObject);
      }
    }
    return result;
  }

  function transformTableData(input, districtNames, fieldName) {
    const result = [];
    const years = Object.keys(input[Object.keys(input)[0]]);

    for (const year of years) {
      const transformedObject = { Year: year };
      for (const [countyDistrictCode, yearData] of Object.entries(input)) {
        if (yearData[year]) {
          const districtName = districtNames[countyDistrictCode];
          if(['BUDGET'].includes(fieldName)){
            transformedObject[districtName] = parseFloat(formatPrecision(yearData[year][fieldName]/1000000,6));
          } else {
            transformedObject[districtName] = yearData[year][fieldName];
          }
        }
      }
      result.push(transformedObject);
    }
    return result;
  }

  const peerNameMap = useMemo(() => {
    const map = {};
    map[defaultSchool.DISTRICT_COUNTY_CODE] = defaultSchool.DISTRICT_NAME;
    for (let i = 0; i < defaultSchool.peerGroupOptions.IDs.length; i++) {
      map[defaultSchool.peerGroupOptions.IDs[i]] = defaultSchool.peerGroupOptions.names[i];
    }
    return map;
  }, [defaultSchool]);

  useEffect(() => {
    setTransformedTableData(transformTableData(graphData, peerNameMap, graphMetric));
  }, [graphData, peerNameMap]);

  useEffect(() => {
    setSchoolData(transformGraphData(graphData));
  }, [graphData]);

  useEffect(() => {
    setIsBoardView(boardView);
  }, [boardView]);

  useEffect(() => {
    setSelectedDistricts([defaultSchool['DISTRICT_NAME']]);
  }, [defaultSchool]);

  const handleCheckboxChange = (districtName) => {
    if (districtName === defaultSchool['DISTRICT_NAME']) return;

    setSelectedDistricts(prevSelected =>
      prevSelected.includes(districtName)
        ? prevSelected.filter(d => d !== districtName)
        : [...prevSelected, districtName]
    );
  };

  return (
    <div className={`card ${expanded ? 'expanded' : ''}`} style={{ backgroundColor: bgColor }} onClick={!isBoardView ? toggleExpand : undefined}>
      <div className="card-header">
        <div className="card-title">
          {title}
          <span
            className="info-icon"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            ?
          </span>
          <div className={`tooltip ${hovered ? 'visible' : ''}`}>
            {definition}
          </div>
        </div>
        {!isBoardView && <div className="card-small-text">{smallText}</div>}
      </div>

      <div className={`card-value ${expanded ? 'inline' : ''}`}>
        {value}
      </div>

      {!isBoardView && (
        <div className="card-arrow-container">
          <div className="card-arrow">
            {arrowDirection === 'up' && '▲'}
            {arrowDirection === 'down' && '▼'}
            {arrowDirection === 'flat' && '▶'}
          </div>
          <div className="card-arrow-label">{YoY}</div>
        </div>
      )}

      {expanded && (
        <div className={`card-graph-container ${expanded ? 'visible' : ''}`}>
          {viewMode === 'graph' ? (
            <div
              className="card-graph visible"
              onClick={(e) => e.stopPropagation()} // Prevents toggleExpand from triggering
            >
              {console.log('defaultSchool: ', defaultSchool)}
              {console.log('sortByDistrictName(schoolData, defaultSchool)', sortByDistrictName(schoolData, defaultSchool.DISTRICT_NAME))}
              <LineGraph
                data={sortByDistrictName(schoolData, defaultSchool)}
                yAxis={graphMetric}
                xAxis="YEAR"
                groupLabel="DISTRICT_NAME"
                showLegend={true}
                yAxisLabel={yAxisTitle}
              />
            </div>
          ) : (
            <div className="card-table">
              <Table
                data={transformedTableData}
                selectedSchoolInfo={defaultSchool}
              />
            </div>
          )}
          <button onClick={toggleViewMode} className="toggle-button">
            {viewMode === 'graph' ? 'View Raw Data' : 'View Graph'}
          </button>
        </div>
      )}
    </div>
  );
};

export default Card;
