import React, { useState, useEffect } from 'react';
import { database } from '../../firebaseConfig';
import { ref, get, set } from "firebase/database";
import '../../styles/Dashboard.css';

const balanceTheme = {
    backgroundColor: '#e7f4fc',
    inputColor: '#ffffff',
    textColor: '#333333'
};

const initialStepDowns = Array(60).fill(0);
const initialEscalators = Array(60).fill(1.0);

const SalaryScheduleClassified = ({ selectedDistrictInfo }) => {
    const [stepDowns, setStepDowns] = useState(initialStepDowns);
    const [escalators, setEscalators] = useState(initialEscalators);
    const [categories, setCategories] = useState([]);
    const [stepsToDisplay, setStepsToDisplay] = useState(60);
    const [defaultStepDown, setDefaultStepDown] = useState(0);
    const [defaultEscalator, setDefaultEscalator] = useState(1.0);
    const [salaryTable, setSalaryTable] = useState([]);
    const [fiscalYear, setFiscalYear] = useState(2025); // Default to the current year

    useEffect(() => {
        loadSavedSchedule();
    }, [selectedDistrictInfo, fiscalYear]);

    useEffect(() => {
        createSalaryTable();
    }, [stepDowns, escalators, categories, stepsToDisplay]);

    const loadSavedSchedule = async () => {
        const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
        const scheduleRef = ref(database, `salarySchedules/${districtCode}/${fiscalYear}/classified`);
    
        try {
            const snapshot = await get(scheduleRef);
            if (snapshot.exists()) {
                const savedData = snapshot.val();
                setStepDowns(savedData.stepDowns || initialStepDowns);
                setEscalators(savedData.escalators || initialEscalators);
                setDefaultStepDown(savedData.defaultStepDown || 0);
                setDefaultEscalator(savedData.defaultEscalator || 1.0);
                setCategories(savedData.categories || []);

            } else {
                alert("No schedule found for the selected fiscal year.");
            }
        } catch (error) {
            console.error("Error loading data:", error);
        }
    };
    

    const createSalaryTable = () => {
        const tableData = [];
        for (let step = 1; step <= stepsToDisplay; step++) {
            const row = {
                step,
                stepDown: stepDowns[step - 1] || defaultStepDown,
                escalator: escalators[step - 1] || defaultEscalator,
            };

            categories.forEach((category) => {
                let currentPay = category.basePay;
                if (step > 1) {
                    const previousPay = parseFloat(tableData[step - 2][category.name] || 0);
                    currentPay = previousPay * (1 + row.escalator / 100) + row.stepDown;
                }
                row[category.name] = currentPay.toFixed(2);
            });

            tableData.push(row);
        }
        setSalaryTable(tableData);
    };

    const saveToFirebase = async () => {
        const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
        const scheduleRef = ref(database, `salarySchedules/${districtCode}/${fiscalYear}/classified`);

        // Transform salaryTable to the desired structure
        const transformedTable = {};
        categories.forEach((category) => {
            transformedTable[category.name] = {};
            salaryTable.forEach((row) => {
                transformedTable[category.name][`${row.step}`] = {
                    hourlyRate: row[category.name],
                    details: category.details || ""
                };
            });
        });

        const dataToSave = {
            stepDowns,
            escalators,
            defaultStepDown,
            defaultEscalator,
            categories,
            salaryTable: transformedTable,
        };

        try {
            await set(scheduleRef, dataToSave);
            alert("Salary schedule saved successfully!");
        } catch (error) {
            console.error("Error saving data:", error);
            alert("Failed to save salary schedule. Please try again.");
        }
    };

    const updateDefaultValues = (field, value) => {
        const numericValue = parseFloat(value) || 0;
        let customValues = [];
    
        if (field === "stepDown") {
            stepDowns.forEach((stepValue, index) => {
                if (stepValue !== defaultStepDown) {
                    customValues.push(`Step Down at Step ${index + 1}`);
                }
            });
        } else if (field === "escalator") {
            escalators.forEach((escValue, index) => {
                if (escValue !== defaultEscalator) {
                    customValues.push(`Escalator at Step ${index + 1}`);
                }
            });
        }
    
        if (customValues.length > 0) {
            const confirmMessage = `You are about to overwrite custom values, are you sure you want to continue? Custom values at:\n${customValues.join('\n')}`;
            if (!window.confirm(confirmMessage)) {
                return; // Exit if the user cancels
            }
        }
    
        if (field === "stepDown") {
            setDefaultStepDown(numericValue);
            setStepDowns(Array(stepsToDisplay).fill(numericValue));
        } else if (field === "escalator") {
            setDefaultEscalator(numericValue);
            setEscalators(Array(stepsToDisplay).fill(numericValue));
        }
    };
    

    const addCategory = () => {
        setCategories([...categories, { name: "", basePay: 0, details: "" }]);
    };

    const updateCategory = (index, field, value) => {
        const updatedCategories = [...categories];
        updatedCategories[index][field] = field === "basePay" ? parseFloat(value) || 0 : value;
        setCategories(updatedCategories);
    };

    const removeCategory = (index) => {
        if (window.confirm("Are you sure you want to delete this category?")) {
            setCategories(categories.filter((_, i) => i !== index));
        }
    };

    const handleInputChange = (index, field, value) => {
        const numericValue = parseFloat(value) || 0;
        if (field === "stepDown") {
            const updatedStepDowns = [...stepDowns];
            updatedStepDowns[index] = numericValue;
            setStepDowns(updatedStepDowns);
        } else if (field === "escalator") {
            const updatedEscalators = [...escalators];
            updatedEscalators[index] = numericValue;
            setEscalators(updatedEscalators);
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <h3>Manage Classified Staff Categories</h3>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                    type="number"
                    value={fiscalYear}
                    onChange={(e) => setFiscalYear(parseInt(e.target.value) || new Date().getFullYear())}
                    style={{ marginRight: '10px', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    placeholder="Fiscal Year"
                />
                <button onClick={addCategory} style={{ marginRight: '10px', padding: '10px', backgroundColor: '#007BFF', color: '#fff', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>
                    Add Category
                </button>
                <button onClick={saveToFirebase} style={{ backgroundColor: '#007BFF', color: '#fff', borderRadius: '4px', border: 'none', padding: '10px', cursor: 'pointer' }}>
                    Save Schedule
                </button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <label style={{ marginRight: '10px' }}>Default Step Down ($):</label>
                <input
                    type="number"
                    value={defaultStepDown}
                    onChange={(e) => updateDefaultValues("stepDown", e.target.value)}
                    style={{ marginRight: '20px', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                />
                <label style={{ marginRight: '10px' }}>Default Step Down (%):</label>
                <input
                    type="number"
                    value={defaultEscalator}
                    onChange={(e) => updateDefaultValues("escalator", e.target.value)}
                    style={{ marginRight: '20px', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                />
                <label style={{ marginRight: '10px' }}>Steps to Display:</label>
                <input
                    type="number"
                    value={stepsToDisplay}
                    onChange={(e) => setStepsToDisplay(parseInt(e.target.value))}
                    style={{ padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px', width: '60px' }}
                />
            </div>
            <table className="salary-table" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', backgroundColor: balanceTheme.backgroundColor, borderRadius: '8px' }}>
                <thead>
                    {/* First Header Row with Input Fields */}
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        {categories.map((category, index) => (
                            <th key={index} style={{ border: '1px solid #b3c7d6', padding: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Category Name"
                                        value={category.name}
                                        onChange={(e) => updateCategory(index, "name", e.target.value)}
                                        style={{ marginBottom: '5px', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Base Pay"
                                        value={category.basePay}
                                        onChange={(e) => updateCategory(index, "basePay", e.target.value)}
                                        style={{ marginBottom: '5px', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                    />
                                    <textarea
                                        placeholder="Details & Notes"
                                        value={category.details}
                                        onChange={(e) => updateCategory(index, "details", e.target.value)}
                                        rows={5}
                                        style={{ resize: 'none', padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                    />
                                    <button
                                        onClick={() => removeCategory(index)}
                                        style={{ marginTop: '5px', padding: '5px', backgroundColor: '#ff4d4d', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </th>
                        ))}
                    </tr>
                    {/* Second Header Row with Column Names */}
                    <tr>
                        <th style={{ border: '1px solid #b3c7d6', padding: '10px' }}>Step</th>
                        <th style={{ border: '1px solid #b3c7d6', padding: '10px' }}>Step Down ($)</th>
                        <th style={{ border: '1px solid #b3c7d6', padding: '10px' }}>Step Down (%)</th>
                        {categories.map((category, index) => (
                            <th key={index} style={{ border: '1px solid #b3c7d6', padding: '10px' }}>{category.name || "Category Name"}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {salaryTable.map((row, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid #b3c7d6', padding: '10px' }}>{row.step}</td>
                            <td style={{ border: '1px solid #b3c7d6', padding: '10px' }}>
                                <input
                                    type="number"
                                    value={row.stepDown}
                                    onChange={(e) => handleInputChange(index, "stepDown", e.target.value)}
                                    style={{ padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                />
                            </td>
                            <td style={{ border: '1px solid #b3c7d6', padding: '10px' }}>
                                <input
                                    type="number"
                                    value={row.escalator}
                                    onChange={(e) => handleInputChange(index, "escalator", e.target.value)}
                                    style={{ padding: '5px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                />
                            </td>
                            {categories.map((category) => (
                                <td key={category.name} style={{ border: '1px solid #b3c7d6', padding: '10px' }}>{row[category.name]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SalaryScheduleClassified;
