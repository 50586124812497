import React, { useState, useEffect } from 'react';
import Card from './Card';
import GeneralDrilldownTable from './GeneralDrilldownTable';
import '../styles/BudgetDash.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {assignLevels} from '../utils'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BudgetDash = ({ selectedDistrictInfo, boardView, dashboardData, drilldownData }) => {

  let levelDict = assignLevels(drilldownData, 'LABEL');
  drilldownData.forEach(item => item['LEVEL'] = levelDict[item.LABEL]);

  const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

  // Helper functions
  const formatPrecision = (num, precision) => (num ? parseFloat(num).toFixed(precision) : 'N/A');

  const calcYoYchange = (num_old, num_new) => { 
    return (100 * (num_new - num_old) / num_old).toFixed(0);
  };

  function calculateAverage(array) {
    if (array.length === 0) {
      return 0;
    }
  
    const sum = array.reduce((acc, num) => acc + num, 0);
    return sum / array.length;
  }

  const getArrowDirection = (num) => {
    if (num > 0) return 'up';
    if (num < 0) return 'down';
    return 'flat';
  };

  function sortByDistrictName(data, name) {
    return data.sort((a, b) => {
        // Place elements with DISTRICT_NAME === name at the beginning
        if (a.DISTRICT_NAME === name && b.DISTRICT_NAME !== name) {
            return -1; // a comes first
        }
        if (a.DISTRICT_NAME !== name && b.DISTRICT_NAME === name) {
            return 1; // b comes first
        }
        // Maintain original order for others
        return 0;
    });
  }

  // Fetch and process data based on selected school
  selectedDistrictInfo['peer_INSTRUCTION_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_INSTRUCTION']));
  selectedDistrictInfo['peer_SUPPORT_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_SUPPORT']));
  selectedDistrictInfo['peer_NONINSTRUCTION_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_NONINSTRUCTION']));
  selectedDistrictInfo['peer_SALARIES_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_SALARIES_AND_BENEFITS']));

  return (
    <div className="budget-dashboard">
      <div className="title">Budget Breakdown</div>
      <div className="dashboard-cards">
        <Card
          defaultSchool={selectedDistrictInfo}
          title="Instruction"
          value={formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_INSTRUCTION, 0) + '%' || '$0'}
          YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_INSTRUCTION,
                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_INSTRUCTION) + '% YoY' || '0% YoY'}
          smallText={`Peers: ${formatPrecision(selectedDistrictInfo['peer_INSTRUCTION_avg'],0) + '%' || '$0'}`}
          bgColor="#88b0d8"
          arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_INSTRUCTION,
                                                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_INSTRUCTION))}`}
          definition="The percentage of the total budget spent on direct instruction, including teacher salaries, classroom materials, and other costs directly related to educating students."
          graphMetric="BUDGET_PERCENT_INSTRUCTION"
          yAxisTitle='Percent (%)'
          boardView={boardView}
          graphData={dashboardData}
        />
        <Card
          defaultSchool={selectedDistrictInfo}
          title="Support"
          value={formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SUPPORT, 0) + '%' || '$0'}
          YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_SUPPORT,
                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SUPPORT) + '% YoY' || '0% YoY'}
          smallText={`Peers: ${formatPrecision(selectedDistrictInfo['peer_SUPPORT_avg'],0) + '%' || '$0'}`}
          bgColor="#d8abc1"
          arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_SUPPORT,
                                                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SUPPORT))}`}
          definition="The percentage of the total budget spent on support services, including counseling, food service, library services, transportation, and other non-instructional staff and resources."
          graphMetric="BUDGET_PERCENT_SUPPORT"
          yAxisTitle='Percent (%)'
          boardView={boardView}
          graphData={dashboardData}
        />
        <Card
          defaultSchool={selectedDistrictInfo}
          title="Non-Instruction"
          value={formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_NONINSTRUCTION, 0) + '%' || '$0'}
          YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_NONINSTRUCTION,
                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_NONINSTRUCTION) + '% YoY' || '0% YoY'}
          smallText={`Peers: ${formatPrecision(selectedDistrictInfo['peer_NONINSTRUCTION_avg'],0) + '%' || '$0'}`}
          bgColor="#87b6a7"
          arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_NONINSTRUCTION,
                                                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_NONINSTRUCTION))}`}
          definition="The percentage of the total budget spent on non-instructional expenses such as administration, maintenance, and other operational costs."
          graphMetric="BUDGET_PERCENT_NONINSTRUCTION"
          yAxisTitle='Percent (%)'
          boardView={boardView}
          graphData={dashboardData}
        />
        <Card
          defaultSchool={selectedDistrictInfo}
          title="Salary & Benefits"
          value={formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SALARIES_AND_BENEFITS, 0) + '%' || '$0'}
          YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_SALARIES_AND_BENEFITS,
                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SALARIES_AND_BENEFITS) + '% YoY' || '0% YoY'}
          smallText={`Peers: ${formatPrecision(selectedDistrictInfo['peer_SALARIES_avg'],0) + '%' || '$0'}`}
          bgColor="#f79f79"
          arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_SALARIES_AND_BENEFITS,
                                                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SALARIES_AND_BENEFITS))}`}
          definition="The percentage of the operating (fund 1, fund 2) budget allocated to salaries and benefits for all district employees, including teachers, administrators, and support staff (Funds 6110, 6150, 6200 as share of budget)."
          graphMetric="BUDGET_PERCENT_SALARIES_AND_BENEFITS"
          yAxisTitle='Percent (%)'
          boardView={boardView}
          graphData={dashboardData}
        />
      </div>
      

      {/* Table Integration */}
      {!boardView && ( // Only render header and DrilldownTable when boardView is false
        <>
          <h1 className="title">Expense Details</h1>
          <div className="drilldownTableContainer">
            <GeneralDrilldownTable
              data = {sortByDistrictName(drilldownData, selectedDistrictInfo['DISTRICT_NAME'])}
              tableDataFilters = {`item.YEAR==='${maxYear}'`}
              tableDataFormats= {{'TOTAL_ALL_FUNDS': 'dollars'}}
              rowKey = 'LABEL'
              columnKey = 'DISTRICT_NAME'
              valueLabel = 'TOTAL_ALL_FUNDS'
              rowLevelFunctions = {{'graph' : true, 'edit' : false}}             
            />
          </div>
        </>
      )}


    </div>
  );
};

export default BudgetDash;
